#report{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
}
#report h1{
    text-align: center;
    margin: 20px 0px;
}

#report *{
    margin: 5px 0px;
}
#report input,#report textarea{
    border-radius: 25px;
    padding: 10px;
}

#report button{
    padding: 10px;
    text-align: center;
    color: #17422d;
    background-color: #70d460;
    border-radius: 12px;
    border: none;
    outline: none;
    align-self: center;
    font-weight: bold;
    width: 50%;
}