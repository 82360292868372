#SignIn{
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background-color:#143626;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: url('../Images/20240120_201954_302.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#SignIn form{
    width: 20%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: white;
    margin-right: 12em;
}
#SignIn form label{
    padding-left: 10px;
}
#SignIn form h1{
    text-align: center;
    font-size: 2.5em;
}
#SignIn form button{
    height: 50px;
    padding: 10px;
    text-align: center;
    color: #17422d;
    background-color: #70d460;
    border-radius: 12px;
    border: none;
    outline: none;
    font-weight: bold;
}
#SignIn form input,#SignIn form select{
    padding: 12px;
    border: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0.232);
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 3px;
    color: white;
}
.secondoption{
    background: url('../Images/20240121_080424_637.jpg') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}
.already-have a{
    color: white;
}
.google-btn {
    height: 50px;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #4285f4;
    color: white;
    border-radius: 12px;
    border: none;
    outline: none;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.google-btn:hover {
    background-color: #357ae8;
}

.google-icon {
    margin-right: 8px;
}

.or {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0; /* Adds space above and below the entire section */
    color: white;
    font-weight: bold;
}

.or p {
    border-top: 1px solid white;
    width: 100%;
    text-align: center;
    position: relative;
    margin: 0;
    padding-top: 10px; /* Adds some padding above the line */
    padding-bottom: 10px; /* Adds some padding below the line */
}

.or p:before {
    content: "OR";
    background-color: #143626; /* Same as background color to blend in */
    padding: 0 10px;
    position: absolute;
    top: -12px; /* Adjust position for "OR" text */
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.2em; /* Slightly bigger text */
    padding-left: 5px;
    padding-right: 5px;
}

